import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { htmlSerializer } from '../utils/htmlSerializer';
import Layout from '../components/layout';
import FullWidthContainer from '../components/containers/fullWidthContainer';
import HeroContent from '../components/heroContent';
import { linkResolver } from '../utils/linkResolver';
import FullWidthImageCollection from '../components/images/fullWidthImageCollection';
import LeftDownSlantCallToActionContainer from '../components/containers/LeftDownSlantCallToActionContainer';
import SectionTitle from '../components/sectionTitle';
import { convertToBgImage } from 'gbimage-bridge';
import { RichText } from 'prismic-reactjs';
import { hyperlinkHtmlSerializer } from '../utils/hyperlinkHtmlSerializer';
import { paragraphHtmlSerializer } from '../utils/paragraphHtmlSerializer';

const ProgramsPage = ({ data }) => {
  const {
    title,
    banner_image,
    banner_graphic,
    banner_description_content,
    top_images,
    bottom_images,
    bottom_section_text,
    bottom_section_title,
    middle_content_group,
    middle_section_title,
    top_section_text,
    top_section_title,
    call_to_action_link_text,
    next_page_link,
  } = data.prismicPrograms.data;

  const backgroundImage = convertToBgImage(banner_image.gatsbyImageData);
  const backgroundImageFluidStack = [`${banner_graphic.url}`, backgroundImage];

  const ctaLink = linkResolver(next_page_link);

  return (
    <Layout
      pageTitle={RichText.asText(title.richText)}
      backgroundImageFluidStack={backgroundImageFluidStack}
      logoClassName='max-w-xxxxs xl:max-w-xxxs mega:max-w-xxs'
      heroSlantClassName='bottom-left-slant-down-right'
      useTallBannerBackground
      contentInsideBackground={() => (
        <FullWidthContainer className='xl:mt-6 mega:mt-16'>
          <HeroContent
            heroTitle={RichText.asText(title.richText)}
            heroDescription={banner_description_content.richText}
            rightAlign
          />
        </FullWidthContainer>
      )}
    >
      <div className='mx-auto my-8 max-w-7xl px-6 md:px-24 lg:px-32 xl:mt-16 mega:mt-32 mega:px-48'>
        <SectionTitle
          text={RichText.asText(top_section_title.richText)}
          usePrimaryTextColor
        />
        <div className=' mb-6 leading-relaxed  text-gray-700'>
          <PrismicRichText
            field={top_section_text.richText}
            components={htmlSerializer}
          />
        </div>
      </div>
      <FullWidthImageCollection
        className='bottom-left-slant-down-right'
        images={top_images}
      />
      <div className='left-angled-down-right-top-only -mt-8 bg-denimblue pt-20 pb-12 text-gray-100 md:-mt-10 lg:-mt-16 lg:pt-24 mega:-mt-20'>
        <SectionTitle
          text={RichText.asText(middle_section_title.richText)}
          useWhiteTextColor
        />
        {middle_content_group.map((item, index) => {
          const sectionTitle = RichText.asText(item.section_title.richText);
          const sectionContent = item.section_content;

          return (
            <div
              key={index}
              className='mx-auto max-w-7xl px-6 md:text-justify lg:px-32 mega:px-48'
            >
              <SectionTitle text={sectionTitle} useWhiteTextColor />
              <div className='py-4 leading-relaxed'>
                <PrismicRichText
                  field={sectionContent.richText}
                  components={{
                    paragraph: paragraphHtmlSerializer,
                    hyperlink: hyperlinkHtmlSerializer,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <FullWidthImageCollection
        className='-mt-12 lg:mt-0'
        images={bottom_images}
      />
      <div className='mx-auto mt-8 mb-20 max-w-7xl px-6 lg:mb-32 lg:px-32 mega:mt-24 mega:mb-40 mega:px-48 giant:my-48'>
        <SectionTitle
          text={RichText.asText(bottom_section_title.richText)}
          usePrimaryTextColor
        />
        <div className='text-gray-700'>
          <PrismicRichText
            field={bottom_section_text.richText}
            components={htmlSerializer}
          />
        </div>
      </div>
      <LeftDownSlantCallToActionContainer
        ctaUrl={ctaLink.url}
        ctaText={RichText.asText(call_to_action_link_text.richText)}
      />
    </Layout>
  );
};

export const query = graphql`
  query ProgramsPageQuery {
    prismicPrograms {
      data {
        banner_description_content {
          richText
        }
        banner_graphic {
          alt
          gatsbyImageData
          url
        }
        banner_image {
          alt
          gatsbyImageData
          url
        }
        bottom_images {
          image {
            alt
            gatsbyImageData
            url
          }
        }
        bottom_section_text {
          richText
        }
        bottom_section_title {
          richText
        }
        call_to_action_link_text {
          richText
        }
        middle_content_group {
          section_content {
            richText
          }
          section_title {
            richText
          }
        }
        middle_section_title {
          richText
        }
        next_page_link {
          uid
          url
        }
        title {
          richText
        }
        top_images {
          image {
            gatsbyImageData
            alt
            url
          }
        }
        top_section_text {
          richText
        }
        top_section_title {
          richText
        }
      }
    }
  }
`;

export default ProgramsPage;
